const paypadDto = {
  username: null,
  pwd: null,
  description: null,
  longitude: null,
  latitude: null,
  idCurrency: 0,
  currency: null,
  status: 1,
  idOffice: 0,
  office: null,
};

export default paypadDto;
