const roleDto = {
  id: 0,
  role: null,
  routes: [],
  idUserCreated: 0,
  userCreated: null,
  idUserUpdated: 0,
  userUpdated: null,
};

export default roleDto;
