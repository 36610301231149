const clientDto = {
  id: 0,
  name: null,
  nit: null,
  email: null,
  phone: null,
  idRegion: 0,
  region: null,
  logoImgList: [],
  logoImg: null,
  imgExt: null,
};

export default clientDto;
